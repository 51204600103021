input[type='checkbox'] {
	margin-right: 5px;
	width: 16px;
	height: 16px;
	border: 0;
	vertical-align: top;
	border-radius: 3px;
	appearance: none;
	border: 1px solid #d2d2d2;
	line-height: 0;

	&:checked {
		border: 0;

		&:before {
			background-color: #71c879;
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 144 144' width='16' height='16'%3E%3Cpath d='M119.1 31a8 8 0 00-11.1 1.6L62.6 94.2 35.4 65A8 8 0 0023.8 76l33.5 35.9.2.2.2.1.4.4.2.2.1.1.2.1.1.1.3.2a12.6 12.6 0 001.1.6h.2c.2.2.4.3.7.3l.7.2h.2l.6.2H64l.7-.1h.1l.5-.2h.2l.5-.2h.2l.6-.3.6-.4a7.5 7.5 0 001.8-1.5l.5-.5 51-69.3a8 8 0 00-1.7-11.2z' fill='%23fff'/%3E%3C/svg%3E");
			display: inline-flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			color: #fff;
			animation: check 0.25s ease;
			border-radius: 3px;
		}
	}

	@keyframes check {
		0% {
			opacity: 0;
			transform: scale(0);
		}
		80% {
			opacity: 0.8;
			transform: scale(1.2);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.fieldInfo {
	font-size: 12px;
	font-weight: 300;
	color: #30323b;
}
