/* 
  We are using BS5 Utility API and modal component ONLY 
  See: 
    - Utility API: https://getbootstrap.com/docs/5.0/utilities/api
    - NgbModal: https://ng-bootstrap.github.io/#/components/modal/api
*/
@import '../node_modules/bootstrap/scss/bootstrap-utilities.scss';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/modal';

/* 
  NgSelect Theme
  See:
    - https://ng-select.github.io/ng-select
*/
@import './scss/ng-select/tagme.theme.scss';

// Import global components
@import './scss/variables';
@import './scss/utils';
@import './scss/alert';
@import './scss/scrollbar';
@import './scss/buttons';
@import './scss/forms';
@import './scss/modal';

/*
  ngx-toastr
  See:
   - https://ngx-toastr.vercel.app/
*/
@import '~ngx-toastr/toastr';
@import './scss/ngx-toastr/tagme.theme.scss';

/* You can add global styles to this file, and also import other style files */
html,
body {
	margin: 0;
	padding: 0;

	height: 100vh;
}

* {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

body {
	background-color: #fff;
	color: #30323b;
}

.empty-state {
	p {
		margin: 0;
		opacity: 0.5;
	}
}

.modal-table-details .modal-body {
	padding: 10px 0 0;
	margin: 0 !important;
}