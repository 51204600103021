@import '../../../node_modules/@ng-select/ng-select/scss/mixins';

$ng-select-highlight: #ff7f40 !default;
$ng-select-primary-text: #30323b !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #eaeaea !default;
$ng-select-border-radius: 3px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 36) !default;
$ng-select-marked: #eaeaea !default;
$ng-select-box-shadow: inset 0 0 0 2px #ff7f40 !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 40px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;

.ng-select {
	&.ng-select-opened {
		> .ng-select-container {
			background: $ng-select-bg;
			border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);
			.ng-arrow {
				top: -2px;
				border-color: transparent transparent darken($ng-select-border, 20);
				border-width: 0 5px 5px;
				&:hover {
					border-color: transparent transparent darken($ng-select-border, 60);
				}
			}
		}
		&.ng-select-bottom {
			> .ng-select-container {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&.ng-select-top {
			> .ng-select-container {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
	}
	&.ng-select-focused {
		& > .ng-select-container:before {
			opacity: 1;
		}
	}
	&.ng-select-disabled {
		> .ng-select-container {
			background-color: $ng-select-disabled-text;
		}
	}
	.ng-has-value .ng-placeholder {
		display: none;
	}
	.ng-select-container {
		color: $ng-select-primary-text;
		background-color: $ng-select-bg;
		border-radius: $ng-select-border-radius;
		border: 1px solid $ng-select-border;
		min-height: $ng-select-height;
		box-shadow: inset 0 0 0 0 transparent;
		transition: box-shadow 255ms ease;
		&:before {
			content: '';
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			box-shadow: 0 0 0 2px $ng-select-highlight, 0 0 0 4px #fff;
			transition: opacity 0.2s ease;
			border-radius: 3px;
			margin: 2px;
		}
		.ng-value-container {
			align-items: center;
			padding-left: $ng-select-value-padding-left;
			@include rtl {
				padding-right: $ng-select-value-padding-left;
				padding-left: 0;
			}
			.ng-placeholder {
				color: $ng-select-placeholder;
				font-style: italic;
				font-weight: 300;
			}
		}
	}
	&.ng-select-single {
		.ng-select-container {
			height: $ng-select-height;
			.ng-value-container {
				.ng-input {
					top: 5px;
					left: 0;
					padding-left: $ng-select-value-padding-left;
					padding-right: 50px;
					@include rtl {
						padding-right: $ng-select-value-padding-left;
						padding-left: 50px;
					}
				}
			}
		}
	}
	&.ng-select-multiple {
		&.ng-select-disabled {
			> .ng-select-container .ng-value-container .ng-value {
				background-color: $ng-select-disabled-text;
				border: 1px solid lighten($ng-select-border, 10);
				.ng-value-label {
					padding: 0 5px;
				}
			}
		}
		.ng-select-container {
			.ng-value-container {
				padding-left: 7px;
				@include rtl {
					padding-right: 7px;
					padding-left: 0;
				}
				.ng-value {
					font-size: $ng-select-value-font-size;
					margin-bottom: 5px;
					background-color: $ng-select-highlight;
					border-radius: 2px;
					margin-right: 5px;
					border: 1px solid $ng-select-highlight;
					color: #fff;
					border-radius: 20px;
					display: inline-flex;
					flex-direction: row-reverse;
					overflow: hidden;
					@include rtl {
						margin-right: 0;
						margin-left: 5px;
					}
					&.ng-value-disabled {
						background-color: $ng-select-disabled-text;
						border-color: $ng-select-disabled-text;
						color: #696970;
						.ng-value-label {
							padding-left: 5px;
							@include rtl {
								padding-left: 0;
								padding-right: 5px;
							}
						}
					}
					.ng-value-label {
						display: inline-block;
						padding: 1px 8px;
					}
					.ng-value-icon {
						display: inline-block;
						padding: 1px 5px;
						&:hover {
							background-color: darken($ng-select-highlight, 5);
						}
						// &.left {
						//   border-right: 1px solid $ng-select-border;
						//   @include rtl {
						//     border-left: 1px solid $ng-select-border;
						//     border-right: none;
						//   }
						// }
						// &.right {
						//   border-left: 1px solid $ng-select-border;
						//   @include rtl {
						//     border-left: 0;
						//     border-right: 1px solid $ng-select-border;
						//   }
						// }
					}
				}
				.ng-input {
					padding: 0 0 0 3px;
					margin-top: -4px;
					@include rtl {
						padding: 0 3px 0 0;
					}
					& > input {
						min-height: auto;
					}
				}
				.ng-placeholder {
					top: 50%;
					transform: translateY(-50%);
					//padding-bottom: 5px;
					padding-left: 3px;
					@include rtl {
						padding-right: 3px;
						padding-left: 0;
					}
				}
			}
		}
	}
	.ng-clear-wrapper {
		color: darken($ng-select-border, 20);
		font-size: 0;
		&:hover .ng-clear {
			color: #d0021b;
		}
	}
	.ng-spinner-zone {
		padding: 5px 5px 0 0;

		@include rtl {
			padding: 5px 0 0 5px;
		}
	}
	.ng-arrow-wrapper {
		width: 25px;
		padding-right: 5px;
		@include rtl {
			padding-left: 5px;
			padding-right: 0;
		}
		&:hover {
			.ng-arrow {
				border-top-color: darken($ng-select-border, 40);
			}
		}
		.ng-arrow {
			border-color: darken($ng-select-border, 20) transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
		}
	}
}

.ng-dropdown-panel {
	background-color: $ng-select-bg;
	border: 1px solid $ng-select-border;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	left: 0;
	&.ng-select-bottom {
		top: 100%;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top: 0;
		margin-top: 0;
		.ng-dropdown-panel-items {
			.ng-option {
				&:last-child {
					border-bottom-right-radius: 4px;
					border-bottom-left-radius: 4px;
				}
			}
		}
	}
	&.ng-select-top {
		bottom: 100%;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-color: lighten($ng-select-border, 10);
		margin-bottom: -1px;
		.ng-dropdown-panel-items {
			.ng-option {
				&:first-child {
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
				}
			}
		}
	}
	.ng-dropdown-header {
		border-bottom: 1px solid $ng-select-border;
		padding: 5px 7px;
	}
	.ng-dropdown-footer {
		border-top: 1px solid $ng-select-border;
		padding: 5px 7px;
	}
	.ng-dropdown-panel-items {
		.ng-optgroup {
			user-select: none;
			padding: 8px 10px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.54);
			cursor: pointer;
			&.ng-option-disabled {
				cursor: default;
			}
			&.ng-option-marked {
				background-color: $ng-select-marked;
			}
			&.ng-option-selected,
			&.ng-option-selected.ng-option-marked {
				background-color: $ng-select-selected;
				font-weight: 600;
			}
		}
		.ng-option {
			background-color: $ng-select-bg;
			color: rgba(0, 0, 0, 0.87);
			padding: 8px 10px;
			&.ng-option-selected,
			&.ng-option-selected.ng-option-marked {
				color: $ng-select-primary-text;
				background-color: $ng-select-selected;
				.ng-option-label {
					font-weight: 600;
				}
			}
			&.ng-option-marked {
				background-color: $ng-select-marked;
				color: $ng-select-primary-text;
			}
			&.ng-option-disabled {
				color: lighten($ng-select-primary-text, 60);
			}
			&.ng-option-child {
				padding-left: 22px;
				@include rtl {
					padding-right: 22px;
					padding-left: 0;
				}
			}
			.ng-tag-label {
				font-size: 80%;
				font-weight: 400;
				padding-right: 5px;
				@include rtl {
					padding-left: 5px;
					padding-right: 0;
				}
			}
		}
	}

	@include rtl {
		direction: rtl;
		text-align: right;
	}
}
