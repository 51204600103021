.alert {
	font-family: 'Open Sans';
	font-size: 14px;
	line-height: 18px;
	border-radius: 3px;
	padding: 10px;
	box-sizing: border-box;

	color: #696970;
	background: #f8f8f8;
	border: 1px solid #eaeaea;

	&-alert {
		color: #d09636;
		background: #fffde5;
		border: 1px solid #ffe5a4;
	}

	&-danger {
		color: #de1d48;
		background: #fff3f6;
		border: 1px solid #fedee5;
	}

	&-success {
		color: #71c879;
		background: #e5ffe7;
		border: 1px solid #ccf1cc;
	}

	&-hint {
		color: #04b4f5;
		background: #e1f5ff;
		border: 1px solid #c1e4f7;
	}
}
