.full-width {
	margin: 0 -15px;
	padding: 0 15px;
}

.bordered {
	border: 1px solid #eaeaea;
	border-radius: 3px;
}

.bg-gray {
	background: #f8f8f8;
}

.fw-300 {
	font-weight: 300;
}

.text {
	&-danger {
		color: #de1d48;
	}
}

.container {
	display: flow-root;
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 1200px;
}

.app-loading-overlay {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1061;
	background: rgba(255, 255, 255, 0.8);
}

.separator {
	display: flex;
	align-items: center;

	&:before,
	&:after {
		content: '';
		flex: 1;
		transform: translateY(calc(-50% + 1px));
		border-bottom: 1px solid #eaeaea;
	}

	&:before {
		margin-right: 1rem;
	}
	&:after {
		margin-left: 1rem;
	}
}

.pub {
	&_img {
		aspect-ratio: 560/124;
		width: 100%;
		height: auto;
		max-height: 124px;
	}
}
