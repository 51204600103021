:root {
	--delivery-color: 255, 127, 64;
	--takeaway-color: 152, 128, 210;
	--scheduling-color: 4, 180, 245;
	--fast-color: 208, 150, 54;
	--error-color: 222, 29, 72;
	--success-color: 113, 200, 121;

	--delivery-lighten-color: 255, 253, 229;
	--takeaway-lighten-color: 239, 233, 255;
	--scheduling-lighten-color: 225, 245, 255;
	--fast-lighten-color: 255, 253, 229;
	--error-lighten-color: 255, 255, 255;


  --page-bg: #fefefe;
  --widget-bg: #fff;
  --primary-color: #ff813c;
  --text-color: #30323B;
  --background-color: #fefefe;
  --accent-background-color: #f8f8f8;
  --border-color: #eaeaea;

  --green-mid: #71C879;
  --green-mid-light: #CCF1CC;
  --green-light: #E5FFE7;

  --gray-light: #f8f8f8;
  --gray-mid-light: #eaeaea;

  --yellow-mid: #E2AC54;
  --yellow-mid-light: #FFE5A4;
  --yellow-light: #fffde2;

  --red-mid: #de1d48;
  --red-mid-light: #FEDEE5;
  --red-light: #FFF3F6;


}
