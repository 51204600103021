.toast-success {
	background-color: #71c879;
}
.toast-error {
	background-color: #de1d48;
}
.toast-info {
	background-color: #04b4f5;
}
.toast-warning {
	background-color: #d09636;
}

.toast-message {
	font-size: 0.7em;
}
