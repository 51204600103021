::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
::-webkit-scrollbar-corner {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
}
