.button {
	overflow: hidden;
	position: relative;

	&:active:before {
		content: '';
		background: rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&--disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}

	&--big {
		border: 1px solid #eaeaea;
		border-radius: 3px;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		height: 44px;
		text-align: center;
		line-height: 1rem;
		transition: color 0.3s ease;
		cursor: pointer;

		@media screen and (max-width: 767px) {
			line-height: 0.9rem;
			margin-bottom: 10px;
		}

		@at-root {
			a#{&} {
				font-weight: 600;
				color: inherit;
				text-decoration: none;
			}

			@media screen and (max-width: 767px) {
				a#{&} {
					font-size: 0.8rem;
				}
			}
		}

		&--checked {
			color: rgb(var(--success-color));
		}
	}
}

.btn {
	&--warning {
		.button {
			background: #de1d48;
			color: #fff;
		}
	}

	&--outline {
		&--warning {
			.button {
				color: #de1d48;
				border: 1px solid #de1d48;
				background: #fff;
			}
		}
	}
}
