.modal {
	&-dialog {
		margin: 4rem 0.5rem;

		@media (min-width: 576px) {
			margin: 4rem auto;
		}
	}

	.modal-content {
		border: 1px solid #eaeaea;
		box-sizing: border-box;
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
		border-radius: 3px;
	}

	&-drawer {
		margin: 0;
		left: 0;
		width: 80%;

		&-left {
			left: 0;
		}

		&-right {
			right: 0;
		}

		.modal-content {
			height: 100vh;
			overflow: scroll;
			border-radius: 0;
			border: 0;
		}
	}

	&.fade {
		.modal-drawer {
			transform: translate(-100%, 0);
		}
		.modal-drawer-left {
			transform: translate(-100%, 0);
		}
		.modal-drawer-right {
			transform: translate(100%, 0);
		}
	}

	&.show {
		.modal-drawer {
			transform: none;
		}
	}

	&-sheet {
		overflow: hidden;

		.modal-content {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border: 0;
		}

		&.fade {
			.modal-dialog {
				transform: translate(0, 0);
				bottom: -100%;
				margin: 0 auto;
			}
		}

		&.show {
			.modal-dialog {
				transform: translate(0, -100%);
			}
		}
	}

	&__new-order {
		margin: 0;

		.modal-content {
			border-radius: 0;
		}

		.modal-body {
			padding: 0 !important;
		}

		iframe {
			height: 100%;
		}

		.modal-body,
		app-modal,
		app-order-modal {
			height: 100%;
		}
	}
}
